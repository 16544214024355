import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Wrapper from '../styles/utilities/Wrapper';
import SocialList from './modules/SocialList';

import { colors, misc } from '../styles/utilities/settings';
import Tread, { STread } from './modules/Tread';

const date = new Date();
const currentYear = date.getFullYear();

const Footer = () => {
  const {
    footer: { copyright },
  } = useStaticQuery(graphql`
    {
      footer: sanitySiteSettings {
        copyright
      }
    }
  `);
  return (
    <SFooter>
      <Tread />
      <Wrapper>
        <div className="copy">
          <p
            dangerouslySetInnerHTML={{
              __html: copyright.replace('[date]', currentYear),
            }}
          />
        </div>
        <div>
          Follow Us
          <SocialList />
        </div>
      </Wrapper>
    </SFooter>
  );
};

export default Footer;

const SFooter = styled.footer`
  background-color: ${colors.black};
  padding: 50px 0 150px;
  position: relative;
  color: ${colors.white};
  overflow: hidden;

  ${Wrapper} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      &:nth-child(2) {
        line-height: 15px;
        display: flex;
        align-items: center;
        color: ${colors.gold};
        text-transform: uppercase;
      }

      ul {
        margin-left: 20px;
      }
    }
  }

  svg {
    transition-duration: ${misc.animSpeed};
  }

  a {
    display: inline-block;

    &:hover {
      svg {
        fill: ${colors.blue};
      }
    }
  }

  .copy {
    font-size: 14px;
    text-transform: uppercase;
  }

  ${Wrapper} {
    margin-top: 20px;
  }

  ${STread} {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
