import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Star = ({ color, size }) => (
  <svg height={size || '21'} viewBox="0 0 23 21.874">
    <path
      id="Path_93"
      data-name="Path 93"
      d="M-391.04-57.3l2.715,8.355h8.785l-7.107,5.164,2.715,8.355-7.107-5.164-7.107,5.164,2.715-8.355-7.107-5.164h8.785Z"
      transform="translate(402.54 57.303)"
      fill={color || colors.gold}
    />
  </svg>
);

export default Star;
