import React, { useState } from 'react';
import { Link } from 'gatsby';
import PageLink from '../helpers/PageLink';

const Menu = ({ content, noSub }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <ul className="menu">
      {content &&
        content.items.map(({ _key, link, subItems }, index) => (
          <li
            key={_key}
            onMouseLeave={() => {
              setActiveIndex(null);
            }}
            className={`${subItems ? 'hasSub' : ''} ${
              activeIndex === index ? 'open' : ''
            }`}
          >
            {(link.page || link.url) === '#' ? (
              <span>{link.copy}</span>
            ) : (
              <>
                {link.newTab ? (
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onFocus={() =>
                      subItems ? setActiveIndex(index) : setActiveIndex(null)
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: link.copy,
                      }}
                    />
                  </a>
                ) : (
                  <Link
                    onFocus={() =>
                      subItems ? setActiveIndex(index) : setActiveIndex(null)
                    }
                    to={link.url}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: link.copy,
                      }}
                    />
                  </Link>
                )}
              </>
            )}

            {subItems && !noSub && (
              <a
                href={null}
                onClick={() => {
                  setActiveIndex(activeIndex === index ? null : index);
                }}
                className="subToggle"
              >
                {activeIndex === index ? '-' : '+'}
              </a>
            )}
            {subItems && (
              <ul>
                {subItems.map((subItem) => (
                  <li key={subItem._key}>
                    <PageLink content={subItem} />
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
    </ul>
  );
};

export default Menu;
