import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Play = ({ color, size }) => (
  <svg width={size || '172.941'} viewBox="0 0 172.941 172.941">
    <g transform="translate(9.756 9.756)">
      <rect
        width="153.429"
        height="153.429"
        rx="70.459"
        transform="translate(0 0)"
        fill="none"
        stroke={color || colors.black}
        strokeMiterlimit="10"
        strokeWidth="19.512"
      />
      <path
        d="M-146.088,464.039V385.271l71.251,39.384Z"
        transform="translate(196.952 -347.94)"
        fill={color || colors.black}
      />
    </g>
  </svg>
);

export default Play;
