import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Pin = ({ color, size }) => (
  <svg height={size || 20} viewBox="0 0 13.792 19.49">
    <g transform="translate(0)">
      <path
        id="Path_127"
        data-name="Path 127"
        d="M-179.467,211.342c-3.809,0-6.9,3.794-6.9,7.6s5.03,11.888,6.9,11.888,6.9-8.079,6.9-11.888S-175.658,211.342-179.467,211.342Zm0,11.094a3.421,3.421,0,0,1-3.421-3.421,3.42,3.42,0,0,1,3.421-3.42,3.421,3.421,0,0,1,3.42,3.42A3.421,3.421,0,0,1-179.467,222.436Z"
        transform="translate(186.363 -211.342)"
        fill={color || colors.black}
      />
    </g>
  </svg>
);

export default Pin;
