import React from 'react';
import { colors } from '../../styles/utilities/settings';

const LinkedIn = ({ color, secondaryColor, size }) => (
  <svg height={size || 60} viewBox="0 0 60 60">
    <g fill="none" fillRule="evenodd" id="flat" stroke="none" strokeWidth="1">
      <g id="linkedin">
        <path
          d="M30,60 C46.5685433,60 60,46.5685433 60,30 C60,13.4314567 46.5685433,0 30,0 C13.4314567,0 0,13.4314567 0,30 C0,46.5685433 13.4314567,60 30,60 Z"
          fill={color || colors.black}
          id="Linkedin"
        />
        <path
          d="M21.8830754,43.8231512 L21.8830754,25.5243389 L16.22737,25.5243389 L16.22737,43.8231512 L21.8830754,43.8231512 Z M21.8830754,19.0382145 C21.8493882,17.40066 20.7580156,16.1538462 18.9850543,16.1538462 C17.2116316,16.1538462 16.0528846,17.40066 16.0528846,19.0382145 C16.0528846,20.6402023 17.1779444,21.9230769 18.9172185,21.9230769 L18.9509057,21.9230769 C20.7580156,21.9230769 21.8830754,20.6402023 21.8830754,19.0382145 Z M31.1100418,43.8231517 L31.1100418,33.3973729 C31.1100418,32.8394014 31.1503704,32.28143 31.3144472,31.8836682 C31.7630341,30.7682778 32.7839561,29.6136637 34.498199,29.6136637 C36.7438958,29.6136637 38.152278,30.8881587 38.152278,33.3973727 L38.152278,43.8231516 L43.8461538,43.8231517 L43.8461538,33.1183872 C43.8461538,27.3839878 40.7850453,24.715669 36.7019099,24.715669 C33.3540813,24.715669 31.8845724,26.5873593 31.0686083,27.861302 L31.1094893,25.4139123 L25.425462,25.4139123 C25.5072241,27.1657215 25.425462,43.8231516 25.425462,43.8231516 L31.1100418,43.8231517 Z"
          fill={secondaryColor || colors.white}
        />
      </g>
    </g>
  </svg>
);

export default LinkedIn;
