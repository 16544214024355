import React from 'react';
import Circle from '../svgs/Circle';
import Square from '../svgs/Square';
import Search from '../svgs/Search';
import Play from '../svgs/Play';
import Cart from '../svgs/Cart';
import AngleArrow from '../svgs/AngleArrow';
import Calendar from '../svgs/Calendar';
import Facebook from '../svgs/Facebook';
import Instagram from '../svgs/Instagram';
import LinkedIn from '../svgs/LinkedIn';
import Twitter from '../svgs/Twitter';
import Pinterest from '../svgs/Pinterest';
import Youtube from '../svgs/Youtube';
import Microphone from '../svgs/Microphone';
import Pin from '../svgs/Pin';
import TireIcon from '../svgs/TireIcon';
import Tread from '../svgs/Tread';
import Star from '../svgs/Star';

const SvgLoader = ({ name, color, secondaryColor, size }) => (
  <>
    {name.toLowerCase() === 'circle' && <Circle color={color} size={size} />}
    {name.toLowerCase() === 'square' && <Square color={color} size={size} />}
    {name.toLowerCase() === 'play' && <Play color={color} size={size} />}
    {name.toLowerCase() === 'anglearrow' && (
      <AngleArrow color={color} size={size} />
    )}
    {name.toLowerCase() === 'search' && <Search color={color} size={size} />}
    {name.toLowerCase() === 'cart' && <Cart color={color} size={size} />}
    {name.toLowerCase() === 'facebook' && (
      <Facebook color={color} size={size} />
    )}
    {name.toLowerCase() === 'instagram' && (
      <Instagram color={color} size={size} />
    )}
    {name.toLowerCase() === 'youtube' && <Youtube color={color} size={size} />}
    {name.toLowerCase() === 'pinterest' && (
      <Pinterest color={color} size={size} />
    )}
    {name.toLowerCase() === 'linkedin' && (
      <LinkedIn color={color} size={size} />
    )}
    {name.toLowerCase() === 'twitter' && <Twitter color={color} size={size} />}
    {name.toLowerCase() === 'calendar' && (
      <Calendar color={color} size={size} />
    )}
    {name.toLowerCase() === 'microphone' && (
      <Microphone color={color} size={size} />
    )}
    {name.toLowerCase() === 'pin' && <Pin color={color} size={size} />}
    {name.toLowerCase() === 'tireicon' && (
      <TireIcon color={color} secondaryColor={secondaryColor} size={size} />
    )}
    {name.toLowerCase() === 'tread' && <Tread color={color} size={size} />}
    {name.toLowerCase() === 'star' && <Star color={color} size={size} />}
  </>
);

export default SvgLoader;
