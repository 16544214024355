import { css } from 'styled-components';
import { breakpoints } from './settings';

export const wrap = css`
  max-width: ${breakpoints.pageWidth}px;
  padding: 0 30px;
  margin: 0 auto;
`;

export const wrapNarrow = css`
  max-width: ${breakpoints.pageWidth / 1.5}px;
  margin: 0 30px;
`;

export const wrapWide = css`
  max-width: ${breakpoints.pageWidth * 1.5}px;
  margin: 0 30px;
`;

export const flex = css`
  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    display: flex;
    &.center {
      align-items: center;
    }
    &.even {
      justify-content: space-between;
    }
  }
`;
