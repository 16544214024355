import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Square = ({ color, size }) => (
  <svg width={size || '239.073'} viewBox="0 0 284.22 284.22">
    <rect
      width="249.22"
      height="249.22"
      transform="translate(17.5 17.5)"
      fill="none"
      stroke={color || colors.black}
      strokeMiterlimit="10"
      strokeWidth="35"
    />
  </svg>
);

export default Square;
