import styled from 'styled-components';
import { breakpoints } from '../utilities/settings';

const Form = styled.form`
  &.singleSubmit {
    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      justify-content: space-between;
    }

    input {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: calc(100% - 195px);
      }

      &[type='submit'] {
        width: 100%;
        @media screen and (min-width: ${breakpoints.ipadPort}px) {
          width: 180px;
        }
      }
    }
  }

  .inner {
    display: flex;
    flex-wrap: wrap;

    label {
      width: 100%;
      display: block;

      &.half {
        width: 50%;
      }

      span {
        display: block;
      }
    }
  }
`;

export default Form;
