import React from 'react';
import Helmet from 'react-helmet';

const Dochead = ({
  title,
  siteName,
  description,
  pageImage,
  metaName,
  metaContent,
  path,
}) => (
  <Helmet
    title={title ? `${title} | ${siteName}` : `${siteName}`}
    meta={[
      { name: 'description', content: description },
      { property: 'og:description', content: description },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: title ? `${title} | ${siteName}` : `${siteName}`,
      },
      { property: 'og:site_name', content: siteName },
      {
        property: 'og:url',
        content: `${process.env.GATSBY_SITEURL}${
          path !== '/' ? `/${path}` : ''
        }`,
      },
      { property: 'og:image', content: pageImage },
      { property: 'og:image:secure_url', content: pageImage },
      { name: metaName, content: metaContent },
      { name: 'newbuild', content: true },
    ]}
  >
    <html lang="en" />
  </Helmet>
);

export default Dochead;
