import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Microphone = ({ color, size }) => (
  <svg height={size || 20} viewBox="0 0 13.062 23.659">
    <path
      d="M-117.175,372.879v-6.124a6.538,6.538,0,0,0-6.531-6.53,6.538,6.538,0,0,0-6.531,6.53v6.124a6.54,6.54,0,0,0,5.249,6.4v2.038h-3.738v2.564h10.04V381.32h-3.738v-2.038A6.54,6.54,0,0,0-117.175,372.879Zm-6.531-10.09a3.971,3.971,0,0,1,3.967,3.966v2.891h-7.934v-2.891A3.971,3.971,0,0,1-123.706,362.789Z"
      transform="translate(130.237 -360.225)"
      fill={color || colors.black}
    />
  </svg>
);

export default Microphone;
