import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Circle = ({ color, size }) => (
  <svg width={size || '239.073'} viewBox="0 0 239.073 239.073">
    <circle
      cx="102.037"
      cy="102.037"
      r="102.037"
      transform="translate(17.5 17.5)"
      fill="none"
      stroke={color || colors.black}
      strokeMiterlimit="10"
      strokeWidth="35"
    />
  </svg>
);

export default Circle;
