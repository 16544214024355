import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Calendar = ({ color, size }) => (
  <svg height={size} viewBox="0 0 14.985 14.985">
    <path
      id="Path_130"
      data-name="Path 130"
      d="M-100,302.817h-9.214A2.889,2.889,0,0,0-112.1,305.7v9.214a2.889,2.889,0,0,0,2.885,2.886H-100a2.889,2.889,0,0,0,2.885-2.886V305.7A2.889,2.889,0,0,0-100,302.817Zm0,13.1h-9.214a1,1,0,0,1-1-1v-5.6H-99v5.6A1,1,0,0,1-100,315.919Z"
      transform="translate(112.099 -302.817)"
      fill={color || colors.black}
    />
  </svg>
);

export default Calendar;
