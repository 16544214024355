import React from 'react';
import { colors } from '../../styles/utilities/settings';

const Youtube = ({ color, size }) => (
  <svg width={size || '18'} viewBox="0 0 24 24">
    <path
      fill={color || colors.black}
      d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
    />
  </svg>
);

export default Youtube;
