import React, { useState, useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { setConfig } from 'react-hot-loader';
import Header from './Header';
import Footer from './Footer';
import { colors } from '../styles/utilities/settings';
import Dochead from './Dochead';
import { AppContext } from './Global';

setConfig({
  showReactDomPatchNotification: false,
});
export const SiteContext = React.createContext();

const Layout = ({ children, title, description, path, pageImage }) => {
  const {
    site,
    site: { _rawMainLogo },
  } = useStaticQuery(graphql`
    {
      site: sanitySiteSettings {
        title
        description
        _rawMainLogo
      }
    }
  `);

  const { getOffset } = useContext(AppContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(false);
  const [headerScroll, setHeaderScroll] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      getOffset();
    }, 200);
  }, []);

  return (
    <SiteContext.Provider
      value={{
        siteTitle: site.title,
        siteDescription: site.description,
        mainLogo: _rawMainLogo,
        headerScroll,
        setHeaderScroll,
        menuOpen,
        modalOpen,
        setMenuOpen,
        setModalOpen,
        searchTerm,
        setSearchTerm,
      }}
    >
      <Dochead
        title={title}
        description={description || site.description}
        path={path}
        siteName={site.title}
        pageImage={pageImage || null}
      />
      <a
        style={{
          position: 'fixed',
          zIndex: '-9999',
          padding: '20px',
          background: colors.black,
          color: colors.white,
          border: `3px solid ${colors.white}`,
        }}
        className="skip-link"
        href="#bodyContent"
      >
        Skip to body.
      </a>
      <main>
        <Header />
        <div id="bodyContent" className="body-content">
          {children}
        </div>
        <Footer />
      </main>
    </SiteContext.Provider>
  );
};

export default Layout;
