import React from 'react';
import styled from 'styled-components';
import SvgLoader from '../helpers/SvgLoader';
import { colors } from '../../styles/utilities/settings';

const Tread = ({ color }) => (
  <STread>
    <SvgLoader name="tread" color={color || colors.darkerGray} size={16} />
    <SvgLoader name="tread" color={color || colors.darkerGray} size={16} />
    <SvgLoader name="tread" color={color || colors.darkerGray} size={16} />
  </STread>
);

export default Tread;

export const STread = styled.div`
  display: flex;
`;
