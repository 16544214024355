import React, { useContext, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import RichText from './contentBlocks/RichText';
import { SiteContext } from './Layout';
import Wrapper from '../styles/utilities/Wrapper';
import {
  colors,
  misc,
  spacing,
  breakpoints,
  font,
} from '../styles/utilities/settings';
import Menu from './modules/Menu';
import Form from '../styles/modules/Form';
import { AppContext } from './Global';
import Tread, { STread } from './modules/Tread';
import imageUrlFor, { buildImageObj } from '../js/imageUrlFor';

const Header = () => {
  const {
    headerScroll,
    setHeaderScroll,
    menuOpen,
    setMenuOpen,
    alertBar,
    mainLogo,
    siteTitle,
  } = useContext(SiteContext);

  const { underline } = useContext(AppContext);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight / 4) {
      setHeaderScroll(true);
    } else {
      setHeaderScroll(false);
    }
  };

  const { mainMenu } = useStaticQuery(graphql`
    {
      mainMenu: sanityMenu(title: { eq: "Main Menu" }) {
        items: _rawItems(resolveReferences: { maxDepth: 20 })
      }
    }
  `);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      handleScroll(e);
    });
    return () => {
      window.removeEventListener('scroll', (e) => {
        handleScroll(e);
      });
    };
  }, []);

  return (
    <SHeader transparent={!headerScroll} menuOpen={menuOpen}>
      <Tread />
      {alertBar && (
        <AlertBar>
          <RichText content={{ copy: alertBar }} />
        </AlertBar>
      )}
      <Wrapper>
        <InnerHeader transparent={!headerScroll} menuOpen={menuOpen}>
          <Link to="/" className="logo">
            <img src={imageUrlFor(buildImageObj(mainLogo))} alt={siteTitle} />
            <p>{siteTitle}</p>
          </Link>
          <MenuToggle
            href={null}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
            close={menuOpen}
          >
            <span />
            <span />
            <span />
          </MenuToggle>
          <MainMenu
            pos={underline && underline[0]}
            lineWidth={underline && underline[1]}
            transparent={!headerScroll}
          >
            <Menu content={mainMenu} menuTitle="Main Menu" />
          </MainMenu>
        </InnerHeader>
      </Wrapper>
    </SHeader>
  );
};

export default Header;

// Menu Toggle Styles
export const MenuToggle = styled.a`
  cursor: pointer;
  position: relative;
  z-index: 3;
  padding: 10px;
  box-sizing: content-box;
  width: 30px;
  transition-duration: ${misc.animSpeed};
  transform: rotate(${({ close }) => (close ? '0deg' : '180deg')});
  background-color: ${colors.black};

  span {
    width: 24px;
    margin: 0 auto;
    height: 2px;
    display: block;
    background-color: ${colors.white};
    transition-duration: ${misc.animSpeed};
    position: relative;

    + span {
      margin-top: 6px;
    }

    &:nth-child(1) {
      transform: rotate(${({ close }) => (close ? '45deg' : '0deg')});
      top: ${({ close }) => (close ? '7px' : '0px')};
    }

    &:nth-child(2) {
      transform: scale(${({ close }) => (close ? '0' : '1')});
    }

    &:nth-child(3) {
      transform: rotate(${({ close }) => (close ? '-45deg' : '0deg')});
      bottom: ${({ close }) => (close ? '9px' : '0px')};
    }
  }

  @media screen and (min-width: ${breakpoints.ipadMid}px) {
    display: none;
  }
`;

// Alert Bar Styles
export const AlertBar = styled.div`
  background-color: ${colors.red};
  color: ${colors.white};
  text-align: center;
  padding: 10px 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  font-size: 16px;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Main Menu Styles
export const MainMenu = styled.nav`
  @media screen and (min-width: ${breakpoints.ipadMid}px) {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
    position: fixed;
    top: 0;
    background-color: ${colors.black};
    width: 80vw;
    height: 100vh;
    transition-duration: ${misc.animSpeed};
    padding-top: ${spacing.headerMobile};
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  ${Form} {
    position: relative;
  }

  > a {
    padding-left: 10px;

    @media screen and (min-width: ${breakpoints.ipadMid}px) {
      cursor: pointer;
    }

    @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
      display: none;
    }

    &:hover {
      svg {
        path {
          @media screen and (min-width: ${breakpoints.ipadMid}px) {
            fill: ${colors.gold} !important;
          }
        }
      }
    }

    svg {
      path {
        ${({ transparent }) =>
          transparent && `fill: ${colors.white} !important;`}
      }
    }
  }

  ul {
    @media screen and (min-width: ${breakpoints.ipadMid}px) {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
    }

    &:before {
      @media screen and (min-width: ${breakpoints.ipadMid}px) {
        content: '';
        display: block;
        height: 4px;
        background-color: ${colors.gold};
        width: ${({ lineWidth }) => lineWidth - 24}px;
        position: absolute;
        z-index: 3;
        left: ${({ pos }) => pos + 12}px;
        top: 100%;
        transition-duration: ${misc.animSpeed};
      }
    }
    > li {
      @media screen and (min-width: ${breakpoints.ipadMid}px) {
        text-align: center;

        + li {
          margin-left: 20px;
        }
      }

      @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
        text-align: right;

        + li {
          border-top: 1px solid ${colors.black};
        }
      }

      &.hasSub {
        position: relative;

        > a:first-of-type {
          @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
            padding-right: 70px;
          }
        }

        &.open {
          ul {
            @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
              display: block;
            }
          }
        }

        &:hover,
        &.open {
          ul {
            @media screen and (min-width: ${breakpoints.ipadMid}px) {
              position: absolute;
              top: 100%;
              display: block;
              width: 200px;
            }
          }
        }
      }

      ul {
        display: none;

        @media screen and (min-width: ${breakpoints.ipadMid}px) {
          background-color: ${colors.white};
          ${({ transparent }) =>
            transparent
              ? ''
              : `box-shadow: 0 10px 10px -15px ${colors.black};`};
        }

        li {
          text-align: left;

          @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
            text-align: right;
            border-top: 1px solid ${colors.gray};
          }
        }

        a {
          color: ${({ transparent }) =>
            transparent ? colors.text : colors.blue};
        }
      }

      > a {
        display: block;
        background-color: ${colors.black};

        @media screen and (min-width: ${breakpoints.ipadMid}px) {
          font-size: 16px;
          line-height: 17px;
          padding: 12px;
        }

        @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
          padding: 15px 20px;
        }
      }
    }
  }

  .subToggle {
    @media screen and (min-width: ${breakpoints.ipadMid}px) {
      display: none;
    }

    @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
      border-left: 1px solid ${colors.black};
      position: absolute;
      right: 0;
      top: 0;
      height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
`;

// Inner Container Styles
export const InnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${MainMenu} {
    @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
      right: ${({ menuOpen }) => (menuOpen ? '0' : '-80vw')};
    }
  }

  .logo {
    display: block;
    position: relative;
    z-index: 3;
    transition-duration: ${misc.animSpeed};
    background-color: ${colors.black};
    padding: 15px 22px 15px 18px;
    display: flex;

    @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
      left: ${({ menuOpen }) => (menuOpen ? '20vw' : '0')};
    }

    img {
      height: 51px;
      width: 51px;
      margin-right: 10px;
    }

    p {
      font-size: 16px;
      line-height: 17px;
      color: ${colors.gold};
      width: 67px;
    }
  }

  ${MenuToggle} {
    path {
      ${({ transparent, menuOpen }) =>
        transparent && !menuOpen && `fill: ${colors.white} !important;`}
    }
  }
`;

// Header Styles
export const SHeader = styled.header`
  padding: 20px 0;
  box-shadow: 0 0 20px -10px ${colors.black};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition-duration: ${misc.animSpeed};
  background-color: ${colors.black};

  @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
    padding: 20px 0;
  }

  &:before {
    @media screen and (max-width: ${breakpoints.ipadMid - 1}px) {
      content: '';
      display: block;
      background-color: ${colors.black};
      position: fixed;
      height: 100vh;
      width: 100vh;
      z-index: -1;
      transition-duration: ${misc.animSpeed};
      opacity: ${({ menuOpen }) => (menuOpen ? '0.7' : '0')};
      top: 0;
      left: 0;
      pointer-events: ${({ menuOpen }) => (menuOpen ? 'all' : 'none')};
    }
  }

  a {
    font-family: ${font.secondary};
    color: ${colors.white};
    position: relative;
    z-index: 2;

    &:hover {
      color: ${colors.gold};
    }
  }

  ${Wrapper} {
    max-width: none;
  }

  ${STread} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
